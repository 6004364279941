<template>
	<svg width="67" height="40" viewBox="0 0 67 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M18.818 25.2068H13.4648L11.3072 17.8326L9.17682 25.2068H3.82368L0 12.0152H5.05272L6.80068 19.2801L9.1222 12.0152H13.5194L15.8136 19.2801L17.5616 12.0152H22.6416L18.818 25.2068Z" fill="black"/>
		<path d="M30.6628 25.5346C28.5507 25.5346 26.7936 24.9064 25.3916 23.6501C24.0078 22.3937 23.3159 20.7095 23.3159 18.5973C23.3159 16.6491 23.9896 15.0104 25.337 13.6812C26.6844 12.352 28.405 11.6874 30.4989 11.6874C32.4836 11.6874 34.1223 12.3611 35.4151 13.7085C36.7079 15.0377 37.3543 16.8403 37.3543 19.1163V20.1268H28.3959C28.5416 20.582 28.8602 20.9735 29.3518 21.3012C29.8435 21.629 30.4716 21.7928 31.2364 21.7928C31.6552 21.7928 32.1832 21.7018 32.8205 21.5197C33.4759 21.3376 33.9585 21.1191 34.268 20.8642L36.2891 23.8959C35.67 24.4239 34.8415 24.8336 33.8037 25.1249C32.7658 25.398 31.7189 25.5346 30.6628 25.5346ZM32.6839 17.0132C32.429 15.9572 31.7007 15.4291 30.4989 15.4291C29.3518 15.4291 28.6326 15.9572 28.3413 17.0132H32.6839Z" fill="black"/>
		<path d="M44.5091 20.318C44.9825 20.9006 45.6653 21.192 46.5575 21.192C47.2494 21.192 47.8321 20.9644 48.3055 20.5092C48.7789 20.0358 49.0156 19.3985 49.0156 18.5973C49.0156 17.8144 48.7789 17.1953 48.3055 16.7401C47.8321 16.2667 47.2494 16.03 46.5575 16.03C45.6836 16.03 45.0007 16.3304 44.5091 16.9313V20.318ZM44.5091 25.2068H39.593V6.98975H44.5091V13.4081C45.4559 12.261 46.6941 11.6874 48.2236 11.6874C49.8987 11.6874 51.2825 12.3065 52.375 13.5446C53.4857 14.7828 54.041 16.467 54.041 18.5973C54.041 20.7823 53.4857 22.4847 52.375 23.7047C51.2825 24.9246 49.8987 25.5346 48.2236 25.5346C46.7669 25.5346 45.5288 24.961 44.5091 23.8139V25.2068Z" fill="black"/>
		<path d="M10.1911 40.0001H9.4371V32.3431L6.2781 40.0001H5.9921L2.8201 32.3431V40.0001H2.0791V31.3291H3.1841L6.1351 38.4791L9.0731 31.3291H10.1911V40.0001Z" fill="#563FDF"/>
		<path d="M24.5436 40.0001H19.0446V31.3291H24.5436V32.0051H19.7856V35.2161H24.4526V35.8921H19.7856V39.3241H24.5436V40.0001Z" fill="#563FDF"/>
		<path d="M38.5765 40.0001H33.0775V31.3291H38.5765V32.0051H33.8185V35.2161H38.4855V35.8921H33.8185V39.3241H38.5765V40.0001Z" fill="#563FDF"/>
		<path d="M50.0744 40.0001H49.3204V32.0051H46.4864V31.3291H52.9084V32.0051H50.0744V40.0001Z" fill="#563FDF"/>
		<path d="M66.3306 9.68799C66.3306 11.3448 64.9874 12.688 63.3306 12.688L53.8126 12.688L53.8126 3.16999C53.8126 1.51314 55.1557 0.169997 56.8126 0.169997L63.3306 0.169998C64.9874 0.169998 66.3306 1.51314 66.3306 3.17L66.3306 9.68799Z" fill="#563FDF"/>
		<path d="M61.0288 9.27487H58.8529L57.7969 8.6649V7.63615H58.7073V8.45551H61.1745V6.34338H59.3081V5.52403H61.1745V3.63039H58.7073V4.44975H57.7969V3.421L58.8529 2.81104H61.0288L62.0849 3.421V5.4694L61.2928 5.9246V5.94281L62.0849 6.39801V8.6649L61.0288 9.27487Z" fill="white"/>
	</svg>
</template>