<template>
	<section id="about" ref="target" :class="{'visible' : visible}">
		<div class="content">
			<transition name="slideUp">
				<h2 v-if="visible" class="title">About Us</h2>
			</transition>
			<transition name="slideUp">
				<p v-if="visible" class="strong">Web3Meet is an app by using which people can find friends, employees, partners and events in web3 all around the world. Create your own hangouts as in the real world as online.</p>
			</transition>
			<transition name="slideUp">
				<p v-if="visible">Using our Web3Meet DNA NFT there will be a more accurate search for people who are suitable for you in terms of level and skill in the web 3 world. With the help of DNA NFT, you can join private communities, stay anonymous, and create events with many different filters for NFT holders.</p>
			</transition>
		</div>
	</section>
</template>
<script>
	import { useElementVisibility } from '@vueuse/core'
	import { ref } from 'vue'
	export default{
		setup() {
			const target = ref(null)
			const targetIsVisible = useElementVisibility(target)

			return {
				target,
				targetIsVisible,
			}
		},
		data() {
			return{
				pos_left: 0,
				pos_top: 0,
				visible: false
			}
		},
		watch:{
			targetIsVisible: function () {
				if(this.targetIsVisible == true) this.visible = true
			},
		}
	}
</script>