<template>
	<section id="roadmap" class="content" ref="target" :class="{'visible' : visible}">
		<h2 class="title">Roadmap</h2>
		<div id="roadmap_wrap">
			<div class="one_road transition_left">
				<div class="road_title">Q3</div>
				<div class="road_year">2022</div>
				<div class="road_desc">Q3 2022 - Early Adopter NFT pass</div>
			</div>
			<div class="road_arrow transition_left"></div>
			<div class="one_road transition_left">
				<div class="road_title">Q2</div>
				<div class="road_year">2023</div>
				<div class="road_desc">Q2 2023 - iOS and Android apps</div>
			</div>
			<div class="road_arrow transition_left"></div>
			<div class="one_road transition_left">
				<div class="road_title">Q3</div>
				<div class="road_year">2023</div>
				<div class="road_desc">Q3 2023 - Desktop platform</div>
			</div>
		</div>
	</section>
</template>
<script>
	import { useElementVisibility } from '@vueuse/core'
	import { ref } from 'vue'
	export default{
		setup() {
			const target = ref(null)
			const targetIsVisible = useElementVisibility(target)

			return {
				target,
				targetIsVisible,
			}
		},
		data() {
			return{
				pos_left: 0,
				pos_top: 0,
				visible: false
			}
		},
		watch:{
			targetIsVisible: function () {
				if(this.targetIsVisible == true) this.visible = true
			},
		}
	}
</script>